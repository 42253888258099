import React, { useState, useEffect, useRef } from 'react';
import {
  Page,
  Layout,
  Card,
  Button,
  Banner,
  Scrollable,
  Toast,
  Loading,
  InlineStack,
  Icon,
  Text,
} from '@shopify/polaris';
import { EditIcon, ClipboardIcon } from '@shopify/polaris-icons';
import axios from 'axios';

export default function RobotsTxtPage() {
  const [robotsTxt, setRobotsTxt] = useState('');
  const [liquidContent, setLiquidContent] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const editableRef = useRef(null);

  const shop = new URLSearchParams(window.location.search).get('shop');

  useEffect(() => {
    const fetchRobotsTxt = async () => {
      try {
        const response = await axios.get(`/api/robots?shop=${shop}`);
        const { enabled, content } = response.data;
        setEnabled(enabled);
        setRobotsTxt(content);
        setLoading(false);
      } catch (error) {
        setToastContent('Failed to fetch robots.txt');
        setToastActive(true);
        setLoading(false);
      }
    };
    fetchRobotsTxt();
  }, [shop]);

  const handleFetchRobotsTxt = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/robots?shop=${shop}`);
      const { content } = response.data;
      setRobotsTxt(content);
      setEnabled(true);
      setEditMode(true);
      setLoading(false);
    } catch (error) {
      setToastContent('Failed to fetch robots.txt from Shopify');
      setToastActive(true);
      setLoading(false);
    }
  };

  const handleUpdateRobotsTxt = async () => {
    setLoading(true);
    try {
      const updatedContent = editableRef.current.innerText;
      const response = await axios.post(`/api/robots?shop=${shop}`, { content: updatedContent });
      setLiquidContent(response.data.liquid_content);
      setEditMode(false);
      setLoading(false);
      setToastContent('robots.txt updated successfully');
      setToastActive(true);
    } catch (error) {
      setToastContent('Failed to update robots.txt');
      setToastActive(true);
      setLoading(false);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(liquidContent).then(() => {
      setToastContent('Copied to clipboard');
      setToastActive(true);
    }, () => {
      setToastContent('Failed to copy');
      setToastActive(true);
    });
  };

  const toastMarkup = toastActive ? (
    <Toast content={toastContent} onDismiss={() => setToastActive(false)} />
  ) : null;

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <Banner
              title="Easily Convert robots.txt into robots.txt.liquid"
              status="info"
              action={
                enabled
                  ? {
                      content: editMode ? 'Save' : 'Edit',
                      onAction: editMode ? handleUpdateRobotsTxt : () => setEditMode(true),
                      icon: editMode ? undefined : EditIcon,
                      disabled: loading,
                    }
                  : {
                      content: 'Fetch robots.txt',
                      onAction: handleFetchRobotsTxt,
                      disabled: loading,
                    }
              }
              secondaryAction={
                !editMode && liquidContent
                  ? {
                      content: 'Copy to clipboard',
                      onAction: handleCopyToClipboard,
                      icon: ClipboardIcon,
                      disabled: loading,
                    }
                  : undefined
              }
            >
              <Text color="subdued" as="p">
                Follow these steps to update your robots.txt:
              </Text>
              <ul style={{ paddingLeft: '20px' }}>
                <li>
                  <Text color="subdued" as="p">1. Click on “Edit” button to edit your robots.txt file</Text>
                </li>
                <li>
                  <Text color="subdued" as="p">2. Copy your liquid content in the right column</Text>
                </li>
                <li>
                  <Text color="subdued" as="p">3. Then go to edit your theme and create “robots.txt.liquid” under Themes {'>'} Templates</Text>
                </li>
                <li>
                  <Text color="subdued" as="p">4. Just save your updated robots.txt file.</Text>
                </li>
              </ul>
              {!editMode && liquidContent && (
                <Text color="subdued" as="p">
                  Copy your liquid content to your template under <strong>theme {'>'} templates {'>'} robots.txt.liquid</strong>
                </Text>
              )}
            </Banner>
            <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
              <Scrollable shadow style={{ height: '500px', flex: 1 }}>
                <pre
                  ref={editableRef}
                  contentEditable={editMode}
                  style={{
                    width: '100%',
                    height: '100%',
                    padding: '10px',
                    border: editMode ? '1px solid #ccc' : 'none',
                    borderRadius: '4px',
                    backgroundColor: editMode ? '#fff' : '#f4f6f8',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    overflowY: 'auto',
                  }}
                >
                  {robotsTxt}
                </pre>
              </Scrollable>
              {liquidContent && (
                <div style={{ flex: 1 }}>
                  <Scrollable shadow style={{ height: '500px' }}>
                    <pre
                      style={{
                        width: '100%',
                        height: '100%',
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#f4f6f8',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        overflowY: 'auto',
                      }}
                    >
                      {liquidContent}
                    </pre>
                  </Scrollable>
                </div>
              )}
            </div>
          </Card>
        </Layout.Section>
      </Layout>
      {loading && <Loading />}
      {toastMarkup}
    </Page>
  );
}
