import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AppProvider, Frame } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import { NavMenu } from '@shopify/app-bridge-react';

import Dashboard from './components/Dashboard';
import SchemaSettings from './components/SchemaSettings';
import Packages from './components/Packages';
import Support from './components/Support';
import RobotsTxtEditor from './components/RobotsTxtEditor';
import { fetchSubscription, clearSubscriptionCache } from './components/subscriptionUtils';
import ArticleSchemaPage from './components/ArticleSchemaPage';
import FAQSchemaPage from './components/FaqSchemaPage';
import RecipeSchemaPage from './components/RecipeSchemaPage';
import LocalBusinessSchemaForm from './components/localBusinessSchema';

function App() {
  const [hasSubscription, setHasSubscription] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const shop = new URLSearchParams(window.location.search).get('shop');

  useEffect(() => {
    const checkSubscription = async () => {
      const subscription = await fetchSubscription(shop);
      
      if (!subscription) {
        setHasSubscription(false); // No active subscription
      } else {
        setHasSubscription(true);
        setSubscriptionPlan(subscription.plan_name); // Handle the subscription details
      }
    };
  
    checkSubscription();
  }, [shop]);
  


  if (hasSubscription === null) {
    return (
      <AppProvider>
        <Frame>
          <div>Loading...</div>
        </Frame>
      </AppProvider>
    );
  }

  return (
    <AppProvider>
      <Frame>
        <Router>
          <NavMenu>
            <a href={`/?shop=${shop}`} rel="home">Home</a>
            <a href={`/dashboard?shop=${shop}`}>Dashboard</a>
            <a href={`/schema-settings?shop=${shop}`}>Schema Settings</a>
            <a href={`/packages?shop=${shop}`}>Packages</a>
            <a href={`/robots-txt-editor?shop=${shop}`}>Robots.txt Editor</a>
            <a href={`/article-schema?shop=${shop}`}>Article Schema</a>
            <a href={`/faq-schema?shop=${shop}`}>FAQ Schema</a>
            <a href={`/recipe-schema?shop=${shop}`}>Recipe Schema</a>
            <a href={`/local-business?shop=${shop}`}>Local Business Schema</a>
            <a href={`/support?shop=${shop}`}>Support</a>
          </NavMenu>
          <Routes>
            <Route path="/" element={<Navigate replace to={hasSubscription ? `/dashboard?shop=${shop}` : `/packages?shop=${shop}`} />} />
            <Route path="/dashboard" element={hasSubscription ? <Dashboard subscriptionPlan={subscriptionPlan} /> : <Navigate replace to={`/packages?shop=${shop}`} />} />
            <Route path="/schema-settings" element={hasSubscription ? <SchemaSettings /> : <Navigate replace to={`/packages?shop=${shop}`} />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/robots-txt-editor" element={hasSubscription ? <RobotsTxtEditor /> : <Navigate replace to={`/packages?shop=${shop}`} />} />
            <Route path="/article-schema" element={hasSubscription && subscriptionPlan === 'Premium' ? <ArticleSchemaPage /> : <Navigate replace to={`/packages?shop=${shop}`} />} />
            <Route path="/faq-schema" element={hasSubscription && subscriptionPlan === 'Premium' ? <FAQSchemaPage /> : <Navigate replace to={`/packages?shop=${shop}`} />} />
            <Route path="/recipe-schema" element={hasSubscription && subscriptionPlan === 'Premium' ? <RecipeSchemaPage /> : <Navigate replace to={`/packages?shop=${shop}`} />} />
            <Route path="/local-business" element={hasSubscription && subscriptionPlan === 'Premium' ? <LocalBusinessSchemaForm /> : <Navigate replace to={`/packages?shop=${shop}`} />} />
            <Route path="/support" element={hasSubscription ? <Support /> : <Navigate replace to={`/packages?shop=${shop}`} />} />
          </Routes>
        </Router>
        {/* Optional: Add a button to clear cache */}
        {/* <button onClick={handleClearCache}>Clear Subscription Cache</button> */}
      </Frame>
    </AppProvider>
  );
}

export default App;
