import React, { useEffect, useState } from 'react';
import { Page, Layout, Card, TextField, Select, Button, Banner } from '@shopify/polaris';
import axios from 'axios';

export default function LocalBusinessSchemaForm() {
  const shop = new URLSearchParams(window.location.search).get('shop');
  const [businessType, setBusinessType] = useState('');
  const [businessTypes, setBusinessTypes] = useState([]);
  const [openingHours, setOpeningHours] = useState([
    {
      dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      opens: '',
      closes: ''
    },
    {
      dayOfWeek: ['Saturday', 'Sunday'],
      opens: '',
      closes: ''
    }
  ]);
  const [enabled, setEnabled] = useState(null); // Null indicates no schema, false indicates schema not enabled, true indicates schema enabled
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchBusinessTypes();
    fetchSettings();
  }, []);

  const fetchBusinessTypes = async () => {
    try {
      const response = await axios.get('/api/localbus/business-types', {
        params: { shop }
      });
      setBusinessTypes(response.data);
    } catch (error) {
      setError('Failed to load business types.');
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await axios.get('/api/localbus', {
        params: { shop }
      });
      const data = response.data;

      if (data && Object.keys(data).length > 0) {
        setBusinessType(data.business_type);
        setOpeningHours(data.opening_hours_specification || openingHours);
        setEnabled(data.enabled); // Set enabled to false or true based on response
      } else {
        setEnabled(null); // No schema found, keep form active
      }
      
    } catch (error) {
      setError('Failed to load settings.');
    }
  };

  const handleOpeningHoursChange = (index, field) => (value) => {
    const newOpeningHours = [...openingHours];
    newOpeningHours[index][field] = value;
    setOpeningHours(newOpeningHours);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await axios.post(`/api/localbus?shop=${shop}`, {
        businessType,
        openingHoursSpecification: openingHours,
      });
      setSuccess('Schema saved successfully!');
      fetchSettings(); // Re-fetch settings to update the state
    } catch (error) {
      setError('Failed to save schema.');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateSchema = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await axios.post(`/api/localbus/generate?shop=${shop}`);
      setSuccess('Schema generated successfully!');
      fetchSettings(); // Re-fetch settings to update the state
      // Redirect back to the dashboard with the premium tab open
      window.location.href = `/dashboard?shop=${shop}&tab=premium`;
    } catch (error) {
      setError('Failed to generate schema.');
    } finally {
      setLoading(false);
    }
  };

  const handleReset = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await axios.post(`/api/localbus/reset?shop=${shop}`);
      setSuccess('Schema reset successfully!');
      fetchSettings(); // Re-fetch settings to update the state
    } catch (error) {
      setError('Failed to reset schema.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Banner title="Schema Settings" status="info">
            <p>Fill out the form to set up your schemas</p>
          </Banner>
        </Layout.Section>
        <Layout.Section>
          <Card title="LocalBusiness Schema" sectioned>
            {error && <Banner status="critical">{error}</Banner>}
            {success && <Banner status="success">{success}</Banner>}
            <div style={{marginTop:'10px'}}></div>
            <Select
              label="Select Your Business Type"
              placeholder="Select a business type"
              options={businessTypes.map((type) => ({ label: type, value: type }))}
              value={businessType}
              onChange={setBusinessType}
              disabled={enabled !== null} // Disable if schema exists
            />
            <div style={{marginTop:'10px'}}></div>
            {openingHours.map((entry, index) => (
              <div key={index}>
                {/* <p>{entry.dayOfWeek.join(', ')}</p> */}
                <TextField
                  label={`Opens (${entry.dayOfWeek.join(', ')})`}
                  placeholder="09:00"
                  value={entry.opens}
                  onChange={handleOpeningHoursChange(index, 'opens')}
                  disabled={enabled !== null} // Disable if schema exists
                />
                <TextField
                  label={`Closes (${entry.dayOfWeek.join(', ')})`}
                  placeholder="17:00"
                  value={entry.closes}
                  onChange={handleOpeningHoursChange(index, 'closes')}
                  disabled={enabled !== null} // Disable if schema exists
                />
              </div>
            ))}
            <div style={{marginTop:'10px'}}></div>
            {enabled === null && (
              <Button variant='primary' tone='success' onClick={handleSubmit} loading={loading} fullWidth>
                Save
              </Button>
            )}
            {enabled === false && (
              <Button variant='primary' tone='success' onClick={handleGenerateSchema} loading={loading} fullWidth>
                Generate Schema
              </Button>
            )}
            {enabled === true && (
              <Button variant='primary' tone='critical' onClick={handleReset} loading={loading} fullWidth>
                Reset
              </Button>
            )}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
