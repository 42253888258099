import React, { useState, useEffect } from 'react';
import {
  Page,
  Layout,
  Card,
  BlockStack,
  InlineStack,
  Text,
  ProgressBar,
  CalloutCard,
  Button,
  Banner,
  Spinner,
  Toast,
  Frame,
  ButtonGroup,
  Icon,
  Loading,
  Tabs,
} from '@shopify/polaris';
import { CheckCircleIcon, XCircleIcon, ClockIcon } from '@shopify/polaris-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Dashboard({ subscriptionPlan }) {
  const navigate = useNavigate();
  const [enabledSchemas, setEnabledSchemas] = useState(Array(5).fill(false));
  const [premiumEnabledSchemas, setPremiumEnabledSchemas] = useState(Array(4).fill(false));
  const [schemaLoading, setSchemaLoading] = useState(Array(9).fill(false));
  const [schemaCdnUrls, setSchemaCdnUrls] = useState(Array(9).fill(''));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [settingsSaved, setSettingsSaved] = useState(false);
  const [showValidateButtons, setShowValidateButtons] = useState(false);
  const [schemaErrors, setSchemaErrors] = useState(Array(9).fill(false));
  const [globalLoading, setGlobalLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const basicSchemaOptions = [
    'corporation',
    'website',
    'breadcrumbs',
    'itemlist',
    'collection',
  ];

  const premiumSchemaOptions = [
    'article',
    'faq',
    'recipe',
    'localBusiness',
  ];

  const isPremiumPlan = subscriptionPlan?.toLowerCase() === 'premium';

  const tabs = [
    {
      id: 'basic',
      content: 'Basic',
      accessibilityLabel: 'Basic schemas',
      panelID: 'basic-content',
    },
    {
      id: 'premium',
      content: 'Premium',
      accessibilityLabel: 'Premium schemas',
      panelID: 'premium-content',
      disabled: !isPremiumPlan,
    },
  ];

  // Check the 'tab' query parameter to determine which tab to select initially
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tabParam = params.get('tab');
    if (tabParam === 'premium' && isPremiumPlan) {
      setSelectedTab(1);
    }
  }, [isPremiumPlan]);

  const handleTabChange = (selectedTabIndex) => {
    console.log('Tab changed to:', selectedTabIndex);  // Debugging tab switching
    setSelectedTab(selectedTabIndex);
  };

  const checkSchemaSettings = async (shop) => {
    try {
      const response = await axios.get('/api/schema/settings', {
        params: { shop },
      });

      const settings = response.data;
      const requiredFields = [
        'organizationType', 'companyName', 'companyLegalName',
        'websiteName',  'telephone', 'address', 'homepageShown',
       
      ];

      const settingsComplete = requiredFields.every(field => settings[field] !== undefined && settings[field] !== '');

      if (!settingsComplete) {
        navigate(`/schema-settings?shop=${shop}`);
      }
    } catch (err) {
      setError('Failed to check schema settings. Please try again.');
      setLoading(false);
      navigate(`/schema-settings?shop=${shop}`);
    }
  };

  const fetchEnabledSchemas = async (shop) => {
    try {
      const response = await axios.get(`/api/dashboard/schema-status`, {
        params: { shop },
      });
      if (response.data.status === 'success') {
        const enabledSchemas = response.data.enabled_schemas;

        const newEnabledSchemas = basicSchemaOptions.map(schema => enabledSchemas.includes(schema));
        setEnabledSchemas(newEnabledSchemas);

        const newPremiumEnabledSchemas = premiumSchemaOptions.map(schema => enabledSchemas.includes(schema));
        setPremiumEnabledSchemas(newPremiumEnabledSchemas);

        const basicSchemasEnabled = newEnabledSchemas.every(Boolean);
        const premiumSchemasEnabled = isPremiumPlan ? newPremiumEnabledSchemas.every(Boolean) : true;

        setSettingsSaved(basicSchemasEnabled && premiumSchemasEnabled);
        setShowValidateButtons(newEnabledSchemas.some(Boolean) || newPremiumEnabledSchemas.some(Boolean));
        
      }
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch enabled schemas. Please try again.');
      setLoading(false);
    
    }
  };

  useEffect(() => {
    const shop = new URLSearchParams(window.location.search).get('shop');
    const initialize = async () => {
      await checkSchemaSettings(shop);
      await fetchEnabledSchemas(shop);
      setLoading(false);
    };
    initialize();
  }, []);

  const handleEnableClick = async (index, isPremium = false) => {
    console.log('Enable Clicked:', { index, isPremium });  // Debugging click

    if (settingsSaved) {
      alert('Settings have been saved. You need to reset before enabling/disabling schemas again.');
      return;
    }

    const schema = isPremium ? premiumSchemaOptions[index] : basicSchemaOptions[index];
    const shop = new URLSearchParams(window.location.search).get('shop');

    const isSchemaLoading = schemaLoading[index + (isPremium ? basicSchemaOptions.length : 0)];

    // Remove the condition that checks if the schema is already enabled
    if (!isSchemaLoading) {
      setGlobalLoading(true);

      setSchemaLoading(prev => {
        const newLoading = [...prev];
        newLoading[index + (isPremium ? basicSchemaOptions.length : 0)] = true;
        return newLoading;
      });

      try {
       
        const response = await fetch(`/api/dashboard/setup/${schema}?shop=${shop}`, { method: 'POST' });
        const data = await response.json();
       

        if (response.ok) {
          if (isPremium) {
            const newPremiumEnabledSchemas = [...premiumEnabledSchemas];
            newPremiumEnabledSchemas[index] = true;
            setPremiumEnabledSchemas(newPremiumEnabledSchemas);

            // Navigate to the correct schema configuration page
            const schemaRoutes = {
              article: '/article-schema',
              faq: '/faq-schema',
              recipe: '/recipe-schema',
              localBusiness: '/local-business',
            };
            const route = schemaRoutes[schema] || '/dashboard';
            console.log('Navigating to:', route);  // Debugging navigation
            navigate(`${route}?shop=${shop}`);
          } else {
            const newEnabledSchemas = [...enabledSchemas];
            newEnabledSchemas[index] = true;
            setEnabledSchemas(newEnabledSchemas);
          }

          setSchemaCdnUrls(prev => {
            const newCdnUrls = [...prev];
            newCdnUrls[index + (isPremium ? basicSchemaOptions.length : 0)] = Array.isArray(data) ? data : [data];
            return newCdnUrls;
          });

          setSchemaErrors(prev => {
            const newErrors = [...prev];
            newErrors[index + (isPremium ? basicSchemaOptions.length : 0)] = false;
            return newErrors;
          });

          setToastContent(`Successfully generated ${schema} schema`);
        } else {
          console.error('Failed to enable schema:', schema);
          setSchemaErrors(prev => {
            const newErrors = [...prev];
            newErrors[index + (isPremium ? basicSchemaOptions.length : 0)] = true;
            return newErrors;
          });
          setToastContent(`Failed to generate ${schema} schema`);
        }
      } catch (error) {
        console.error('Error during fetch operation:', error);
        setSchemaErrors(prev => {
          const newErrors = [...prev];
          newErrors[index + (isPremium ? basicSchemaOptions.length : 0)] = true;
          return newErrors;
        });
        setToastContent(`Error generating ${schema} schema`);
      } finally {
        setToastActive(true);
        setSchemaLoading(prev => {
          const newLoading = [...prev];
          newLoading[index + (isPremium ? basicSchemaOptions.length : 0)] = false;
          return newLoading;
        });
        setGlobalLoading(false);
      }
    }
  };

  const handleSaveSettings = async () => {
    setGlobalLoading(true);

    const shop = new URLSearchParams(window.location.search).get('shop');

    const warning = enabledSchemas.map((enabled, index) => !enabled && basicSchemaOptions[index])
      .concat(premiumEnabledSchemas.map((enabled, index) => !enabled && premiumSchemaOptions[index]))
      .filter(Boolean).join(', ');

    if (warning && !window.confirm(`The following schemas are not enabled: ${warning}. Do you still want to save?`)) {
      setGlobalLoading(false);
      return;
    }

    const schemasInfo = basicSchemaOptions.concat(isPremiumPlan ? premiumSchemaOptions : []).map((schema, index) => ({
      type: schema,
      urls: schemaCdnUrls[index],
      enabled: index < 5 ? enabledSchemas[index] : premiumEnabledSchemas[index - 5],
    }));

    try {
      await axios.post(`/api/dashboard/schema-status?shop=${shop}`, {
        shop: shop,
        enabledSchemas: schemasInfo,
      });

      setSettingsSaved(true);
      setShowValidateButtons(true);
      setToastContent('Settings saved successfully');
      setToastActive(true);

      if (enabledSchemas[2]) {
        await axios.post('/api/dashboard/breadcrumbs', {
          shop: shop,
          script_url: 'https://d1354vy99xqlig.cloudfront.net/breadcrumbs.js'
        });
      }

      await fetchEnabledSchemas(shop);

    } catch (err) {
      setError('Failed to save settings. Please try again.');
    } finally {
      setGlobalLoading(false);
    }
  };

  const handleValidateClick = (schema) => {
    const shopUrl = new URLSearchParams(window.location.search).get('shop');
    let validateUrl = '';

    if (schema === 'corporation' || schema === 'website') {
      validateUrl = `https://validator.schema.org/#url=https://${shopUrl}`;
    } else if (schema === 'itemlist') {
      validateUrl = `https://validator.schema.org/#url=https://${shopUrl}/products`;
    } else if (schema === 'breadcrumbs' || schema === 'collection') {
      validateUrl = `https://validator.schema.org/#url=https://${shopUrl}/collections`;
    }

    window.open(validateUrl, '_blank');
  };

  const handleResetClick = async () => {
    setGlobalLoading(true);

    const shop = new URLSearchParams(window.location.search).get('shop');
    try {
      await axios.post('/api/dashboard/reset', { shop: shop });
      setEnabledSchemas(Array(5).fill(false));
      setPremiumEnabledSchemas(Array(4).fill(false));
      setSettingsSaved(false);
      setShowValidateButtons(false);
      setToastContent('Settings have been reset');
      setToastActive(true);
    } catch (err) {
      setError('Failed to reset settings. Please try again.');
    } finally {
      setGlobalLoading(false);
    }
  };

  const toastMarkup = toastActive ? (
    <Toast content={toastContent} onDismiss={() => setToastActive(false)} />
  ) : null;

  const activeCount = enabledSchemas.filter(Boolean).length + premiumEnabledSchemas.filter(Boolean).length;
  const totalSchemas = isPremiumPlan ? basicSchemaOptions.length + premiumSchemaOptions.length : basicSchemaOptions.length;
  const progress = (activeCount / totalSchemas) * 100;

  const isSaveDisabled = settingsSaved || activeCount === 0;
  const isResetDisabled = activeCount === 0;

  let headerBackgroundColor = '';
  let headerMessage = '';

  if (activeCount === 0) {
    headerBackgroundColor = '#dcd6ff';
    headerMessage = 'Enable the schemas you want to activate';
  } else if (activeCount > 0 && activeCount < totalSchemas) {
    headerBackgroundColor = '#ffedcc';
    headerMessage = 'Complete the remaining schemas for full power';
  } else if (activeCount === totalSchemas && settingsSaved) {
    headerBackgroundColor = '#ccffcc';
    headerMessage = 'All schemas enabled and saved. You are all set!';
  } else if (activeCount === totalSchemas && !settingsSaved) {
    headerBackgroundColor = '#ffedcc';
    headerMessage = 'All schemas enabled. Please save your settings.';
  }
  else if (activeCount > 0 && activeCount < totalSchemas && settingsSaved) {
    headerBackgroundColor = '#ffedcc';
    headerMessage = 'Some schemas enabled and saved. You are set!.';
  }

  if (loading) {
    return (
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <Spinner accessibilityLabel="Loading" size="large" />
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  if (error) {
    return (
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <Banner status="critical">{error}</Banner>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  return (
    <Frame>
      {globalLoading && <Loading />}
      <Page
        fullWidth
        primaryAction={
          <ButtonGroup>
            <Button
              variant='primary'
              disabled={isSaveDisabled}
              onClick={handleSaveSettings}
              tone='success'
            >
              Save Settings
            </Button>
            <Button
              variant='primary'
              disabled={isResetDisabled}
              onClick={handleResetClick}
              tone='critical'
            >
              Reset Settings
            </Button>
          </ButtonGroup>
        }
      >
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange} fitted>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <div style={{ backgroundColor: headerBackgroundColor, padding: '1rem', borderRadius: '5px' }}>
                  <BlockStack gap="tight">
                    <InlineStack gap="extraTight" alignment="center">
                      <ProgressBar progress={progress} size="small" />
                      <Text variant="bodyMd">{activeCount}/{totalSchemas} Activated!</Text>
                    </InlineStack>
                    <Text variant="headingLg">Dashboard</Text>
                    <Text variant="bodyMd">
                      {headerMessage}
                    </Text>
                  </BlockStack>
                </div>
              </Card>
            </Layout.Section>

            <Layout.Section>
              {selectedTab === 0 && basicSchemaOptions.map((option, index) => (
                <CalloutCard
                  key={index}
                  title={option.replace(/([A-Z])/g, ' $1').trim() + ' Schema'}
                  footerActionAlignment="right"
                  primaryAction={{
                    content: schemaLoading[index] ? <Spinner size="small" /> : (enabledSchemas[index] ? 'Enabled' : 'Enable'),
                    onAction: () => {
                      handleEnableClick(index);
                    },
                    primary: !enabledSchemas[index],
                    disabled: globalLoading || schemaLoading[index] || enabledSchemas[index]
                  }}
                  secondaryAction={
                    showValidateButtons && enabledSchemas[index] && {
                      content: 'Validate',
                      onAction: () => handleValidateClick(option),
                    }
                  }
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <p style={{ flex: 1 }}>Click the button to enable {option} for your store.</p>
                    <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                      <Icon size='medium'
                        source={
                          schemaLoading[index] ? ClockIcon : (enabledSchemas[index] ? CheckCircleIcon : (schemaErrors[index] ? XCircleIcon : ClockIcon))
                        }
                        tone={
                          schemaLoading[index] ? 'warning' : (enabledSchemas[index] ? 'success' : (schemaErrors[index] ? 'critical' : 'warning'))
                        }
                      />
                    </div>
                  </div>
                </CalloutCard>
              ))}

              {selectedTab === 1 && premiumSchemaOptions.map((option, index) => (
                <CalloutCard
                  key={index}
                  title={option.replace(/([A-Z])/g, ' $1').trim() + ' Schema'}
                  footerActionAlignment="right"
                  primaryAction={{
                    content: schemaLoading[index + basicSchemaOptions.length] ? <Spinner size="small" /> : (premiumEnabledSchemas[index] ? 'Enabled' : 'Enable'),
                    onAction: () => {
            
                      handleEnableClick(index, true);
                    },
                    primary: !premiumEnabledSchemas[index],
                    disabled: globalLoading || schemaLoading[index + basicSchemaOptions.length] || premiumEnabledSchemas[index]
                  }}
                  secondaryAction={
                    showValidateButtons && premiumEnabledSchemas[index] && {
                      content: 'Validate',
                      onAction: () => handleValidateClick(option),
                    }
                  }
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <p style={{ flex: 1 }}>Click the button to enable {option} for your store.</p>
                    <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                      <Icon size='medium'
                        source={
                          schemaLoading[index + basicSchemaOptions.length] ? ClockIcon : (premiumEnabledSchemas[index] ? CheckCircleIcon : (schemaErrors[index + basicSchemaOptions.length] ? XCircleIcon : ClockIcon))
                        }
                        tone={
                          schemaLoading[index + basicSchemaOptions.length] ? 'warning' : (premiumEnabledSchemas[index] ? 'success' : (schemaErrors[index + basicSchemaOptions.length] ? 'critical' : 'warning'))
                        }
                      />
                    </div>
                  </div>
                </CalloutCard>
              ))}
            </Layout.Section>
          </Layout>
        </Tabs>
        {toastMarkup}
      </Page>
    </Frame>
  );
}