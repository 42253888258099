import React, { useState, useEffect } from 'react';
import {
  Page, Layout, Banner, Button, Toast, Spinner, Card, ResourceList, ResourceItem, Text, Box, TextField, FormLayout, Modal, InlineStack, RadioButton, Checkbox, RangeSlider
} from '@shopify/polaris';
import axios from 'axios';

const shop = new URLSearchParams(window.location.search).get('shop');

export default function RecipeSchemaPage() {
  const [step, setStep] = useState(1);
  const [recipes, setRecipes] = useState([]);
  const [currentRecipe, setCurrentRecipe] = useState({
    name: '',
    prepTime: 0,
    cookTime: 0,
    totalTime: 0,
    recipeYield: '',
    ingredients: [''],
    instructions: ['']
  });
  const [selectedRecipeForMapping, setSelectedRecipeForMapping] = useState(null);
  const [articles, setArticles] = useState([]);
  const [mappedRecipes, setMappedRecipes] = useState([]);
  const [mappedArticles, setMappedArticles] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingArticles, setFetchingArticles] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [editingRecipe, setEditingRecipe] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [articlesFetched, setArticlesFetched] = useState(false);
  const [generateSchemaDisabled, setGenerateSchemaDisabled] = useState(true);
  const [showResetButton, setShowResetButton] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);

  const canSaveRecipe = currentRecipe.ingredients.length > 0 && currentRecipe.instructions.length > 0;

  useEffect(() => {
    fetchRecipes();
    fetchMappedArticles();
  }, []);

  const fetchRecipes = async () => {
    try {
      const response = await axios.get(`/api/recipe/list?shop=${shop}`);
      setRecipes(response.data);
      updateNextButtonState(response.data);
    } catch (error) {
      console.error('Error fetching recipes:', error);
      setToastContent('Failed to fetch recipes.');
      setToastActive(true);
    }
  };

  const fetchMappedArticles = async () => {
    try {
      const response = await axios.get(`/api/recipe/mappings?shop=${shop}`);
      if (Array.isArray(response.data) && response.data.length > 0) {
        const mappedRecipeIds = response.data
          .filter(mapping => Array.isArray(mapping.articles) && mapping.articles.length > 0)
          .map(mapping => mapping.recipe_id);

        const allMapped = response.data.every(mapping => mapping.articles.length > 0);
        const allEnabled = response.data.every(mapping =>
          mapping.articles.length > 0 && mapping.articles.every(article => article.enabled)
        );

        const hasUnmapped = response.data.some(mapping => mapping.articles.length === 0);

        setMappedRecipes(mappedRecipeIds);
        setMappedArticles(response.data.reduce((acc, mapping) => {
          if (Array.isArray(mapping.articles)) {
            mapping.articles.forEach(article => acc.push(article.title));
          }
          return acc;
        }, []));

        if (allEnabled) {
          setShowNextButton(false);
          setGenerateSchemaDisabled(true);
          setShowResetButton(true);
        } else if (allMapped && !allEnabled) {
          setShowNextButton(false);
          setGenerateSchemaDisabled(false);
          setShowResetButton(false);
        } else if (hasUnmapped) {
          setShowNextButton(true);
          setGenerateSchemaDisabled(true);
          setShowResetButton(false);
        } else {
          setShowNextButton(true);
          setGenerateSchemaDisabled(true);
          setShowResetButton(false);
        }
      } else {
        setShowNextButton(false);
        setGenerateSchemaDisabled(true);
        setShowResetButton(false);
      }
    } catch (error) {
      console.error('Error fetching mapped articles:', error);
      setToastContent('Failed to fetch mapped articles.');
      setToastActive(true);
    }
  };

  const fetchArticles = async () => {
    try {
      setFetchingArticles(true);
      const response = await axios.get(`/api/article/blogs-articles?shop=${shop}`);
      if (response.data) {
        setArticles(response.data);
        setSelectedItems([]);
        setArticlesFetched(true);
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
      setToastContent('Failed to fetch articles.');
      setToastActive(true);
    } finally {
      setFetchingArticles(false);
    }
  };

  const updateNextButtonState = (recipes, mappedRecipeIds = mappedRecipes, allMappedArticles = mappedArticles) => {
    const allRecipesMapped = recipes.every(recipe => mappedRecipeIds.includes(recipe.id));
    const hasUnmappedArticles = allMappedArticles.length < recipes.length;

    if (allRecipesMapped) {
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
      setNextButtonDisabled(!hasUnmappedArticles);
    }
  };

  const handleAddRecipe = async () => {
    if (recipes.length >= 10) {
      setToastContent('Maximum 10 recipes allowed.');
      setToastActive(true);
      return;
    }

    if (!currentRecipe.name || !currentRecipe.prepTime || !currentRecipe.cookTime || !currentRecipe.totalTime || !currentRecipe.recipeYield) {
      setToastContent('All fields are required.');
      setToastActive(true);
      return;
    }

    try {
      await axios.post(`/api/recipe/create`, { shop, ...currentRecipe });
      await fetchRecipes();
      setCurrentRecipe({
        name: '',
        prepTime: 0,
        cookTime: 0,
        totalTime: 0,
        recipeYield: '',
        ingredients: [''],
        instructions: ['']
      });
      setToastContent('Recipe created successfully.');
      await fetchMappedArticles();  
      updateNextButtonState([...recipes, currentRecipe]);  
    } catch (error) {
      console.error('Error adding recipe:', error);
      setToastContent('Error adding recipe.');
    } finally {
      setToastActive(true);
    }
  };

  const handleUpdateRecipe = async () => {
    if (!currentRecipe.name || !currentRecipe.prepTime || !currentRecipe.cookTime || !currentRecipe.totalTime || !currentRecipe.recipeYield) {
      setToastContent('All fields are required.');
      setToastActive(true);
      return;
    }

    try {
      await axios.put(`/api/recipe/update/${currentRecipe.id}`, { ...currentRecipe });
      fetchRecipes();
      setEditingRecipe(false);
      setCurrentRecipe({
        name: '',
        prepTime: 0,
        cookTime: 0,
        totalTime: 0,
        recipeYield: '',
        ingredients: [''],
        instructions: ['']
      });
      setToastContent('Recipe updated successfully.');
    } catch (error) {
      console.error('Error updating recipe:', error);
      setToastContent('Error updating recipe.');
    } finally {
      setToastActive(true);
    }
  };

  const handleDeleteRecipe = async (recipeId) => {
    try {
      await axios.delete(`/api/recipe/delete/${recipeId}`);
      await fetchRecipes();
      setToastContent('Recipe deleted successfully.');
      await fetchMappedArticles(); 
    } catch (error) {
      console.error('Error deleting recipe:', error);
      setToastContent('Error deleting recipe.');
    } finally {
      setToastActive(true);
    }
  };

  const handleRecipeChange = (field, value) => {
    setCurrentRecipe({ ...currentRecipe, [field]: value });
  };

  const handleAddIngredient = () => {
    if (currentRecipe.ingredients.length >= 10) {
      setToastContent('Maximum 10 ingredients allowed.');
      setToastActive(true);
      return;
    }
    setCurrentRecipe({ ...currentRecipe, ingredients: [...currentRecipe.ingredients, ''] });
  };

  const handleAddInstruction = () => {
    if (currentRecipe.instructions.length >= 10) {
      setToastContent('Maximum 10 instructions allowed.');
      setToastActive(true);
      return;
    }
    setCurrentRecipe({ ...currentRecipe, instructions: [...currentRecipe.instructions, ''] });
  };

  const handleSaveMappings = async () => {
    if (selectedItems.length === 0 || selectedRecipeForMapping === null) {
      setToastContent('Please select at least one article and a recipe.');
      setToastActive(true);
      return;
    }

    setLoading(true);
    try {
      await axios.post('/api/recipe/map', {
        recipe_id: selectedRecipeForMapping,
        articles: selectedItems,
      });

      setToastContent('Recipe mappings saved successfully.');

      await fetchRecipes();
      await fetchMappedArticles();

      setStep(1);
      setArticlesFetched(false);
    } catch (error) {
      setToastContent('Failed to save recipe mappings.');
      console.error('Error saving mappings:', error);
    } finally {
      setLoading(false);
      setToastActive(true);
    }
  };

  const handleGenerateSchema = async () => {
    setLoading(true);  
    try {
      const response = await axios.post('/api/recipe/generate-schema', { shop });
      if (response.status === 200) {
        setToastContent('Schema generated successfully.');
        setGenerateSchemaDisabled(true);
        await fetchMappedArticles(); 
        // Redirect back to the dashboard with the premium tab open
        window.location.href = `/dashboard?shop=${shop}&tab=premium`;
      } else {
        throw new Error('Failed to generate schema.');
      }
    } catch (error) {
      setToastContent('Failed to generate schema.');
      console.error('Error generating schema:', error);
    } finally {
      setToastActive(true);
      setLoading(false);  
    }
  };

  const handleReset = async () => {
    try {
      const response = await axios.post('/api/recipe/reset', { shop });
      if (response.status === 200) {
        setToastContent('System reset successfully.');
        setGenerateSchemaDisabled(false);
        setShowResetButton(false);
        setStep(1);
        await fetchRecipes();
        await fetchMappedArticles();
      } else {
        throw new Error('Failed to reset.');
      }
    } catch (error) {
      setToastContent('Failed to reset.');
      console.error('Error resetting:', error);
    } finally {
      setToastActive(true);
    }
  };

  const handleSelectionChange = (articleId) => {
    if (selectedItems.includes(articleId)) {
      setSelectedItems(selectedItems.filter(item => item !== articleId));
    } else {
      setSelectedItems([...selectedItems, articleId]);
    }
  };

  const openEditRecipeModal = (recipe) => {
    setCurrentRecipe(recipe);
    setEditingRecipe(true);
    setModalActive(true);
  };

  const closeEditRecipeModal = () => {
    setEditingRecipe(false);
    setCurrentRecipe({
      name: '',
      prepTime: 0,
      cookTime: 0,
      totalTime: 0,
      recipeYield: '',
      ingredients: [''],
      instructions: ['']
    });
    setModalActive(false);
  };

  const toastMarkup = toastActive ? (
    <Toast content={toastContent} onDismiss={() => setToastActive(false)} />
  ) : null;

  
  return (
    <Page fullWidth>
      <Layout>
        {step === 1 && (
          <>
            <Layout.Section>
              <Banner title="Recipe Schema - Create Recipes and map them to articles" status="info">
             
                <Card sectioned title={editingRecipe ? "Edit Recipe" : "Create Recipe"} subdued>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      label="Recipe Name"
                      value={currentRecipe.name}
                      onChange={(value) => handleRecipeChange('name', value)}
                      disabled={recipes.length >= 10 || showResetButton}
                    />
                    <TextField
                      label="Recipe Yield"
                      value={currentRecipe.recipeYield}
                      onChange={(value) => handleRecipeChange('recipeYield', value)}
                      disabled={showResetButton}
                      style={{ maxWidth: '150px' }}
                    />
                  </FormLayout.Group>

                  <FormLayout.Group>
                    <RangeSlider
                      output
                      label="Prep Time (minutes)"
                      min={0}
                      max={200}
                      value={currentRecipe.prepTime}
                      onChange={(value) => handleRecipeChange('prepTime', value)}
                      disabled={showResetButton}
                      suffix={
                        <p
                          style={{
                            minWidth: '24px',
                            textAlign: 'right',
                          }}
                        >
                          {currentRecipe.prepTime}
                        </p>
                      }
                    />
                    <RangeSlider
                      output
                      label="Cook Time (minutes)"
                      min={0}
                      max={200}
                      value={currentRecipe.cookTime}
                      onChange={(value) => handleRecipeChange('cookTime', value)}
                      disabled={showResetButton}
                      suffix={
                        <p
                          style={{
                            minWidth: '24px',
                            textAlign: 'right',
                          }}
                        >
                          {currentRecipe.cookTime}
                        </p>
                      }
                    />
                    <RangeSlider
                      output
                      label="Total Time (minutes)"
                      min={0}
                      max={200}
                      value={currentRecipe.totalTime}
                      onChange={(value) => handleRecipeChange('totalTime', value)}
                      disabled={showResetButton}
                      suffix={
                        <p
                          style={{
                            minWidth: '24px',
                            textAlign: 'right',
                          }}
                        >
                          {currentRecipe.totalTime}
                        </p>
                      }
                    />
                  </FormLayout.Group>

                  <Text variant="headingMd">Ingredients</Text>
                  {currentRecipe.ingredients.map((ingredient, index) => (
                    <TextField
                      key={index}
                      label={`Ingredient ${index + 1}`}
                      value={ingredient}
                      onChange={(value) => {
                        const newIngredients = [...currentRecipe.ingredients];
                        newIngredients[index] = value;
                        handleRecipeChange('ingredients', newIngredients);
                      }}
                      disabled={showResetButton}
                    />
                  ))}
                  <Button variant='secondary' onClick={handleAddIngredient} disabled={currentRecipe.ingredients.length >= 10 || showResetButton} primary>
                    Add Ingredient
                  </Button>

                  <Text variant="headingMd" style={{ marginTop: '20px' }}>Instructions</Text>
                  {currentRecipe.instructions.map((instruction, index) => (
                    <TextField
                      key={index}
                      label={`Instruction ${index + 1}`}
                      value={instruction}
                      onChange={(value) => {
                        const newInstructions = [...currentRecipe.instructions];
                        newInstructions[index] = value;
                        handleRecipeChange('instructions', newInstructions);
                      }}
                      disabled={showResetButton}
                    />
                  ))}
                  <Button variant='secondary' onClick={handleAddInstruction} disabled={currentRecipe.instructions.length >= 10 || showResetButton} primary>
                    Add Instruction
                  </Button>
                </FormLayout>
          
              </Card>
              <div style={{marginTop:'10px' , float: 'right'}}>
                <InlineStack distribution="equalSpacing" spacing="tight">
                  <Button onClick={editingRecipe ? handleUpdateRecipe : handleAddRecipe}  variant='primary' size="large" tone='success' disabled={!canSaveRecipe}>
                    {editingRecipe ? "Update Recipe" : "Save Recipe"}
                  </Button>
                </InlineStack>
                </div>
              </Banner>
            </Layout.Section>

    

        <Layout.Section>
            <Banner title="Saved Recipes" status="info">
              <div style={{ marginTop: '20px' }}>
                <Card sectioned title="Saved Recipes">
                  <ResourceList
                    resourceName={{ singular: 'Recipe', plural: 'Recipes' }}
                    items={recipes}
                    renderItem={(recipe) => {
                      const { id, name } = recipe;
                      const isMapped = mappedRecipes.includes(id);

                      return (
                        <ResourceItem
                          id={id.toString()}
                          accessibilityLabel={`View details for ${name}`}
                          onClick={() => {}}
                        >
                          <InlineStack distribution="equalSpacing" spacing="tight">
                            <Text variant="bodyMd" fontWeight="bold" as="h3" color={isMapped ? 'subdued' : 'default'}>
                              {name}
                            </Text>
                            <div style={{ marginLeft: 'auto' }}>
                              <Button plain onClick={() => openEditRecipeModal(recipe)} disabled={isMapped}>
                                Edit
                              </Button>
                              <Button plain destructive onClick={() => handleDeleteRecipe(id)}>
                                Delete
                              </Button>
                            </div>
                          </InlineStack>
                        </ResourceItem>
                      );
                    }}
                    selectedItems={[]}
                    selectable={false}
                  />
                </Card>
              </div>
         
              {showNextButton && (
                <div style={{ marginTop: '20px',  float: 'right'}}>
                  <Button 
                    variant='primary'
                    tone='success' 
                    fullWidth
                    onClick={() => setStep(2)} 
                    disabled={nextButtonDisabled} 
                  >
                    Next
                  </Button>
                </div>
              )}
        
              {!generateSchemaDisabled && (
                 <div style={{ marginTop: '20px',  float: 'right'}}>
                <Button
                  variant='primary'
                  tone="success"
                  onClick={handleGenerateSchema}
                  disabled={generateSchemaDisabled}
                  loading={loading} 
                  fullWidth
                >
                  Generate Schema
                </Button>
              </div>
              
              )}
              </Banner>
            </Layout.Section>
          </>
        )}

        {step === 2 && (
          <>
            <Layout.Section>
              <Banner title="Recipe Schema Mapping" status="info">
                <p>Select a Recipe and map it to articles.</p>
              </Banner>
            </Layout.Section>

            <Layout.Section>
              <Card sectioned title="Select Recipe">
                <InlineStack vertical spacing="tight">
                  {recipes
                    .filter((recipe) => !mappedRecipes.includes(recipe.id))
                    .map((recipe) => {
                      const { id, name } = recipe;
                      return (
                        <RadioButton
                          key={id}
                          label={name}
                          checked={selectedRecipeForMapping === id}
                          id={id.toString()}
                          name="recipe"
                          onChange={() => {
                            setSelectedRecipeForMapping(id);
                            setArticlesFetched(false);
                          }}
                        />
                      );
                    })}
                  <div style={{ marginLeft: 'auto' }}>
                    <Button variant='primary' onClick={() => setStep(1)}>
                      Back
                    </Button>
                  </div>
                </InlineStack>
              </Card>

              {selectedRecipeForMapping && (
                <>
                  <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Button primary onClick={fetchArticles} disabled={fetchingArticles || !selectedRecipeForMapping || articlesFetched} variant='primary' fullWidth>
                      {fetchingArticles ? 'Fetching Articles...' : 'Fetch Articles'}
                    </Button>
                  </div>

                  {fetchingArticles && <Spinner accessibilityLabel="Fetching articles" size="large" />}

                  {loading || fetchingArticles ? (
                    <Spinner accessibilityLabel="Processing" size="large" />
                  ) : (
                    <Card title="Select Articles to Map" sectioned>
                      <div style={{ maxHeight: '450px', overflowY: 'auto' }}>
                        {articles.map((blog) => (
                          blog.articles.map((article) => (
                            <div key={article.id} style={{ marginBottom: '10px' }}>
                              <Checkbox
                                label={`${blog.blog_title} - ${article.title} by ${article.author}`}
                                checked={selectedItems.includes(article.id)}
                                onChange={() => handleSelectionChange(article.id)}
                                disabled={mappedArticles.includes(article.title)}
                              />
                            </div>
                          ))
                        ))}
                      </div>
                    </Card>
                  )}

                  {selectedItems.length > 0 && (
                    <div style={{ marginTop: '10px' }}>
                      <InlineStack distribution="fillEvenly" spacing="loose">
                        <Button variant='primary' onClick={handleSaveMappings} disabled={loading || selectedItems.length === 0} fullWidth>
                          Save Mappings
                        </Button>
                      </InlineStack>
                    </div>
                  )}
                </>
              )}
            </Layout.Section>
          </>
        )}
      </Layout>
      {toastMarkup}

      {showResetButton && (
        <div style={{ marginTop: '10px' }}>
          <Button
            variant='primary'
            tone="critical"
            fullWidth
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      )}

      <Modal
        open={modalActive}
        onClose={closeEditRecipeModal}
        title={editingRecipe ? "Edit Recipe" : "Create Recipe"}
        primaryAction={{
          content: editingRecipe ? "Update Recipe" : "Save Recipe",
          onAction: editingRecipe ? handleUpdateRecipe : handleAddRecipe,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: closeEditRecipeModal,
          },
        ]}
      >
        <Modal.Section>
          <FormLayout.Group>
            <TextField
              label="Recipe Name"
              value={currentRecipe.name}
              onChange={(value) => handleRecipeChange('name', value)}
              disabled={recipes.length >= 10 || showResetButton}
            />
            <TextField
              label="Recipe Yield"
              value={currentRecipe.recipeYield}
              onChange={(value) => handleRecipeChange('recipeYield', value)}
              disabled={showResetButton}
            />
            <RangeSlider
              output
              label="Prep Time (minutes)"
              min={0}
              max={200}
              value={currentRecipe.prepTime}
              onChange={(value) => handleRecipeChange('prepTime', value)}
              disabled={showResetButton}
              suffix={
                <p
                  style={{
                    minWidth: '24px',
                    textAlign: 'right',
                  }}
                >
                  {currentRecipe.prepTime}
                </p>
              }
            />
            <RangeSlider
              output
              label="Cook Time (minutes)"
              min={0}
              max={200}
              value={currentRecipe.cookTime}
              onChange={(value) => handleRecipeChange('cookTime', value)}
              disabled={showResetButton}
              suffix={
                <p
                  style={{
                    minWidth: '24px',
                    textAlign: 'right',
                  }}
                >
                  {currentRecipe.cookTime}
                </p>
              }
            />
            <RangeSlider
              output
              label="Total Time (minutes)"
              min={0}
              max={200}
              value={currentRecipe.totalTime}
              onChange={(value) => handleRecipeChange('totalTime', value)}
              disabled={showResetButton}
              suffix={
                <p
                  style={{
                    minWidth: '24px',
                    textAlign: 'right',
                  }}
                >
                  {currentRecipe.totalTime}
                </p>
              }
            />
          </FormLayout.Group>

          <Text variant="headingMd">Ingredients</Text>
          {currentRecipe.ingredients.map((ingredient, index) => (
            <TextField
              key={index}
              label={`Ingredient ${index + 1}`}
              value={ingredient}
              onChange={(value) => {
                const newIngredients = [...currentRecipe.ingredients];
                newIngredients[index] = value;
                handleRecipeChange('ingredients', newIngredients);
              }}
              disabled={showResetButton}
            />
          ))}
          <Button variant='primary' onClick={handleAddIngredient} disabled={currentRecipe.ingredients.length >= 10 || showResetButton}>
            Add Ingredient
          </Button>

          <Text variant="headingMd" style={{ marginTop: '20px' }}>Instructions</Text>
          {currentRecipe.instructions.map((instruction, index) => (
            <TextField
              key={index}
              label={`Instruction ${index + 1}`}
              value={instruction}
              onChange={(value) => {
                const newInstructions = [...currentRecipe.instructions];
                newInstructions[index] = value;
                handleRecipeChange('instructions', newInstructions);
              }}
              disabled={showResetButton}
            />
          ))}
          <Button variant='primary' onClick={handleAddInstruction} disabled={currentRecipe.instructions.length >= 10 || showResetButton}>
            Add Instruction
          </Button>
        </Modal.Section>
      </Modal>
    </Page>
  );
}
