export async function fetchSubscription(shop) {
  try {
    // Always fetch from the server
    const response = await fetch(`/api/get_subscription?shop=${shop}`);
    const subscription = await response.json();

    // Handle the case where no active subscription or shop is found
    if (subscription.subscription === null) {
      console.warn('No active subscription or shop found:', subscription.message);
      return null; // No subscription found
    }

    return subscription;
  } catch (error) {
    console.error('fetchSubscription error:', error);
    return null; // Handle error case by returning null
  }
}
