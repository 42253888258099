import React, { useState } from 'react';
import {
  Page,
  Layout,
  Card,
  TextField,
  Button,
  FormLayout,
  Banner,
  InlineStack,
  Toast,
  Frame,
  Loading,
  Divider,
} from '@shopify/polaris';
import axios from 'axios';

export default function SupportPage() {
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [supportMessage, setSupportMessage] = useState('');
  const [supportEmail, setSupportEmail] = useState('');
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  
  const shop = new URLSearchParams(window.location.search).get('shop');

  const handleSendMessage = async () => {
    if (!supportEmail || !supportMessage) {
      setToastContent('Email and Message are required.');
      setToastActive(true);
      return;
    }

    setLoading(true);
    try {
      await axios.post(`/api/support/send-message?shop=${shop}`, {
        messageType: 'Support Message',
        email: supportEmail,
        message: supportMessage,
      });
      setToastContent('Your message has been sent successfully.');
      setSupportEmail('');
      setSupportMessage('');
    } catch (error) {
      setToastContent('Failed to send message.');
    } finally {
      setLoading(false);
      setToastActive(true);
    }
  };

  const handleSendForm = async () => {
    const { name, email, phone, website, message } = contactForm;

    if (!email || !message) {
      setToastContent('Email and Message are required.');
      setToastActive(true);
      return;
    }

    setLoading(true);
    try {
      await axios.post(`/api/support/send-message?shop=${shop}`, {
        messageType: 'Partner Form',
        name,
        email,
        phone,
        website,
        message,
      });
      setToastContent('Your contact information has been sent successfully.');
      setContactForm({
        name: '',
        email: '',
        phone: '',
        website: '',
        message: '',
      });
    } catch (error) {
      setToastContent('Failed to send form.');
    } finally {
      setLoading(false);
      setToastActive(true);
    }
  };

  const toastMarkup = toastActive ? (
    <Toast content={toastContent} onDismiss={() => setToastActive(false)} />
  ) : null;

  return (
    <Frame>
      {loading && <Loading />}
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <Banner title="Customer Support" status="info">
              <p>If you need support, do not hesitate to contact us. Just send your issue and let's fix it!</p>
              <Divider borderColor="transparent" />
              <FormLayout>
                <TextField
                  label="Email"
                  type="email"
                  value={supportEmail}
                  onChange={(value) => setSupportEmail(value)}
                  required
                />
                <TextField
                  label="Message"
                  autosize
                  variant="headingLg"
                  multiline={5}
                  value={supportMessage}
                  onChange={(value) => setSupportMessage(value)}
                  required
                />
                <InlineStack alignment="center" distribution="center">
                  <Button variant="primary" tone="success" onClick={handleSendMessage}>
                    Send
                  </Button>
                </InlineStack>
              </FormLayout>
            </Banner>
          </Layout.Section>

          <Layout.Section>
            <Banner title="Ready to Grow Your Revenue?" status="warning">
              <FormLayout>
                <TextField
                  label="Name - Surname"
                  value={contactForm.name}
                  onChange={(value) => setContactForm({ ...contactForm, name: value })}
                />
                <TextField
                  label="Email Address"
                  type="email"
                  value={contactForm.email}
                  onChange={(value) => setContactForm({ ...contactForm, email: value })}
                  required
                />
                <TextField
                  label="Phone"
                  type="tel"
                  value={contactForm.phone}
                  onChange={(value) => setContactForm({ ...contactForm, phone: value })}
                />
                <TextField
                  label="Website"
                  type="url"
                  value={contactForm.website}
                  onChange={(value) => setContactForm({ ...contactForm, website: value })}
                />
                <TextField
                  label="Message"
                  multiline
                  rows={5}
                  value={contactForm.message}
                  onChange={(value) => setContactForm({ ...contactForm, message: value })}
                  required
                />
                <InlineStack alignment="center" distribution="center">
                  <Button variant="primary" tone="success" onClick={handleSendForm}>
                    Send
                  </Button>
                </InlineStack>
              </FormLayout>
            </Banner>
          </Layout.Section>
        </Layout>
        {toastMarkup}
      </Page>
    </Frame>
  );
}
