import React, { useState, useEffect } from 'react';
import {
  Page, Layout, Banner, Button, Toast, Spinner, Card, ResourceList, ResourceItem, Text, TextField, FormLayout, Modal, InlineStack, RadioButton, Checkbox,
} from '@shopify/polaris';
import axios from 'axios';

const shop = new URLSearchParams(window.location.search).get('shop');

export default function FAQSchemaPage() {
  const [step, setStep] = useState(1);
  const [faqs, setFAQs] = useState([]);
  const [currentFAQ, setCurrentFAQ] = useState({ name: '', questions: [{ question: '', answer: '' }] });
  const [selectedFAQForMapping, setSelectedFAQForMapping] = useState(null);
  const [handles, setHandles] = useState([]);
  const [mappedFAQs, setMappedFAQs] = useState([]);
  const [mappedHandles, setMappedHandles] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingHandles, setFetchingHandles] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [editingFAQ, setEditingFAQ] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [handlesFetched, setHandlesFetched] = useState(false);
  const [generateSchemaDisabled, setGenerateSchemaDisabled] = useState(true);
  const [showResetButton, setShowResetButton] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);

  useEffect(() => {
    fetchFAQs();
    fetchMappedHandles();
  }, []);

  const fetchFAQs = async () => {
    try {
      const response = await axios.get(`/api/faq/list?shop=${shop}`);
      setFAQs(response.data);
      updateNextButtonState(response.data);
    } catch (error) {
      console.error('Error fetching FAQs:', error);
      setToastContent('Failed to fetch FAQs.');
      setToastActive(true);
    }
  };

  const fetchMappedHandles = async () => {
    try {
        const response = await axios.get(`/api/faq/mappings?shop=${shop}`);
        if (Array.isArray(response.data) && response.data.length > 0) {
            const mappedFAQIds = response.data
                .filter(mapping => Array.isArray(mapping.articles) && mapping.articles.length > 0)
                .map(mapping => mapping.faq_id);

            const allMapped = response.data.every(mapping => mapping.articles.length > 0);
            const allEnabled = response.data.every(mapping =>
                mapping.articles.length > 0 && mapping.articles.every(article => article.enabled)
            );

            const hasUnmapped = response.data.some(mapping => mapping.articles.length === 0);

            setMappedFAQs(mappedFAQIds);
            setMappedHandles(response.data.reduce((acc, mapping) => {
                if (Array.isArray(mapping.articles)) {
                    mapping.articles.forEach(article => acc.push(article.handle));
                }
                return acc;
            }, []));

            // Button Behaviors
            if (allEnabled) {
                // All FAQs are mapped and enabled
                setShowNextButton(false);
                setGenerateSchemaDisabled(true);
                setShowResetButton(true);
            } else if (allMapped && !allEnabled) {
                // All FAQs are mapped but not yet enabled
                setShowNextButton(false);
                setGenerateSchemaDisabled(false);
                setShowResetButton(false);
            } else if (hasUnmapped) {
                // There are FAQs with no mappings
                setShowNextButton(true);
                setGenerateSchemaDisabled(true);
                setShowResetButton(false);
            } else {
                // Default case if none of the above match
                setShowNextButton(true);
                setGenerateSchemaDisabled(true);
                setShowResetButton(false);
            }
        } else {
            // No FAQs at all
            setShowNextButton(false);
            setGenerateSchemaDisabled(true);
            setShowResetButton(false);
        }
    } catch (error) {
        console.error('Error fetching mapped handles:', error);
        setToastContent('Failed to fetch mapped handles.');
        setToastActive(true);
    }
};

  const fetchHandles = async () => {
    try {
      setFetchingHandles(true);
      const response = await axios.get(`/api/faq/handles?shop=${shop}`);
      if (response.data) {
        setHandles(response.data);
        setSelectedItems([]);
        setHandlesFetched(true);
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Error fetching handles:', error);
      setToastContent('Failed to fetch handles.');
      setToastActive(true);
    } finally {
      setFetchingHandles(false);
    }
  };

  const updateNextButtonState = (faqs, mappedFAQIds = mappedFAQs, allMappedHandles = mappedHandles) => {
    const allFAQsMapped = faqs.every(faq => mappedFAQIds.includes(faq.id));
    const hasUnmappedArticles = allMappedHandles.length < faqs.length;

    if (allFAQsMapped) {
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
      setNextButtonDisabled(!hasUnmappedArticles);
    }
  };

  const handleAddFAQ = async () => {
    if (faqs.length >= 10) {
        setToastContent('Maximum 10 FAQs allowed.');
        setToastActive(true);
        return;
    }

    if (!currentFAQ.name || currentFAQ.questions.length === 0 || currentFAQ.questions.some(q => !q.question || !q.answer)) {
        setToastContent('FAQ name and all questions and answers are required.');
        setToastActive(true);
        return;
    }

    try {
        await axios.post(`/api/faq/create`, { shop, name: currentFAQ.name, questions: currentFAQ.questions });
        await fetchFAQs();
        setCurrentFAQ({ name: '', questions: [{ question: '', answer: '' }] });
        setToastContent('FAQ created successfully.');
        await fetchMappedHandles();  // Ensure handles are fetched and state is updated
        updateNextButtonState([...faqs, currentFAQ]);  // Ensure the next button state is updated
    } catch (error) {
        console.error('Error adding FAQ:', error);
        setToastContent('Error adding FAQ.');
    } finally {
        setToastActive(true);
    }
};


  const handleUpdateFAQ = async () => {
    if (!currentFAQ.name || currentFAQ.questions.length === 0 || currentFAQ.questions.some(q => !q.question || !q.answer)) {
      setToastContent('FAQ name and all questions and answers are required.');
      setToastActive(true);
      return;
    }

    try {
      await axios.put(`/api/faq/update/${currentFAQ.id}`, { name: currentFAQ.name, questions: currentFAQ.questions });
      fetchFAQs();
      setEditingFAQ(false);
      setCurrentFAQ({ name: '', questions: [{ question: '', answer: '' }] });
      setToastContent('FAQ updated successfully.');
    } catch (error) {
      console.error('Error updating FAQ:', error);
      setToastContent('Error updating FAQ.');
    } finally {
      setToastActive(true);
    }
  };

  const handleDeleteFAQ = async (faqId) => {
    try {
      await axios.delete(`/api/faq/delete/${faqId}`);
      await fetchFAQs();
      setToastContent('FAQ deleted successfully.');
      await fetchMappedHandles(); 
    } catch (error) {
      console.error('Error deleting FAQ:', error);
      setToastContent('Error deleting FAQ.');
    } finally {
      setToastActive(true);
    }
  };

  const handleFAQChange = (index, field, value) => {
    const newQuestions = [...currentFAQ.questions];
    newQuestions[index][field] = value;
    setCurrentFAQ({ ...currentFAQ, questions: newQuestions });
  };

  const handleAddQuestion = () => {
    if (currentFAQ.questions.length >= 10) {
      setToastContent('Maximum 10 questions/answers allowed.');
      setToastActive(true);
      return;
    }
    setCurrentFAQ({ ...currentFAQ, questions: [...currentFAQ.questions, { question: '', answer: '' }] });
  };

  const handleSaveMappings = async () => {
    if (selectedItems.length === 0 || selectedFAQForMapping === null) {
      setToastContent('Please select at least one handle and an FAQ.');
      setToastActive(true);
      return;
    }

    setLoading(true);
    try {
      await axios.post('/api/faq/map', {
        faq_id: selectedFAQForMapping,
        handles: selectedItems,
      });

      setToastContent('FAQ mappings saved successfully.');

      await fetchFAQs();
      await fetchMappedHandles();

      setStep(1);
      setHandlesFetched(false);
    } catch (error) {
      setToastContent('Failed to save FAQ mappings.');
      console.error('Error saving mappings:', error);
    } finally {
      setLoading(false);
      setToastActive(true);
    }
  };

  const handleGenerateSchema = async () => {
    setLoading(true);  
    try {
      const response = await axios.post('/api/faq/generate-schema', { shop });
      if (response.status === 200) {
        setToastContent('Schema generated successfully.');
        setGenerateSchemaDisabled(true);
        await fetchMappedHandles(); 
        // Redirect back to the dashboard with the premium tab open
        window.location.href = `/dashboard?shop=${shop}&tab=premium`;
      } else {
        throw new Error('Failed to generate schema.');
      }
    } catch (error) {
      setToastContent('Failed to generate schema.');
      console.error('Error generating schema:', error);
    } finally {
      setToastActive(true);
      setLoading(false);  
    }
  };

  const handleReset = async () => {
    try {
      const response = await axios.post('/api/faq/reset', { shop });
      if (response.status === 200) {
        setToastContent('System reset successfully.');
        setGenerateSchemaDisabled(false);
        setShowResetButton(false);
        setStep(1);
        await fetchFAQs();
        await fetchMappedHandles();
      } else {
        throw new Error('Failed to reset.');
      }
    } catch (error) {
      setToastContent('Failed to reset.');
      console.error('Error resetting:', error);
    } finally {
      setToastActive(true);
    }
  };

  const handleSelectionChange = (handle) => {
    if (selectedItems.includes(handle)) {
      setSelectedItems(selectedItems.filter(item => item !== handle));
    } else {
      setSelectedItems([...selectedItems, handle]);
    }
  };

  const openEditFAQModal = (faq) => {
    setCurrentFAQ(faq);
    setEditingFAQ(true);
    setModalActive(true);
  };

  const closeEditFAQModal = () => {
    setEditingFAQ(false);
    setCurrentFAQ({ name: '', questions: [{ question: '', answer: '' }] });
    setModalActive(false);
  };

  const toastMarkup = toastActive ? (
    <Toast content={toastContent} onDismiss={() => setToastActive(false)} />
  ) : null;

  return (
    <Page fullWidth>
      <Layout>
        {step === 1 && (
          <>
            <Layout.Section>
              <Banner title="FAQ Schema" status="info">
                <p>Create FAQs and map them to pages.</p>
              </Banner>
            </Layout.Section>

            <Layout.Section>
            <Card sectioned title={editingFAQ ? "Edit FAQ" : "Create FAQ"} subdued>
    <FormLayout>
        <TextField
            label="FAQ Name"
            value={currentFAQ.name}
            onChange={(value) => setCurrentFAQ({ ...currentFAQ, name: value })}
            disabled={faqs.length >= 10 || showResetButton} // Disable if reset is active
        />
        {currentFAQ.questions.map((qa, index) => (
            <FormLayout.Group key={index}>
                <TextField
                    label={`Question ${index + 1}`}
                    value={qa.question}
                    onChange={(value) => handleFAQChange(index, 'question', value)}
                    disabled={showResetButton} // Disable if reset is active
                />
                <TextField
                    label={`Answer ${index + 1}`}
                    value={qa.answer}
                    onChange={(value) => handleFAQChange(index, 'answer', value)}
                    disabled={showResetButton} // Disable if reset is active
                />
            </FormLayout.Group>
        ))}
        <InlineStack distribution="center" spacing="loose">
            <Button onClick={handleAddQuestion} disabled={currentFAQ.questions.length >= 10 || showResetButton} primary>
                Add Question/Answer
            </Button>
            <Button primary onClick={editingFAQ ? handleUpdateFAQ : handleAddFAQ} disabled={faqs.length >= 10 || showResetButton}>
                {editingFAQ ? "Update FAQ" : "Save FAQ"}
            </Button>
        </InlineStack>
    </FormLayout>
</Card>


              <div style={{ marginTop: '20px' }}>
                <Card sectioned title="Saved FAQs">
                  <ResourceList
                    resourceName={{ singular: 'FAQ', plural: 'FAQs' }}
                    items={faqs}
                    renderItem={(faq) => {
                      const { id, name } = faq;
                      const isMapped = mappedFAQs.includes(id);

                      return (
                        <ResourceItem
                          id={id.toString()}
                          accessibilityLabel={`View details for ${name}`}
                          onClick={() => {}}
                        >
                          <InlineStack distribution="equalSpacing" spacing="tight">
                            <Text variant="bodyMd" fontWeight="bold" as="h3" color={isMapped ? 'subdued' : 'default'}>
                              {name}
                            </Text>
                            <div style={{ marginLeft: 'auto' }}>
                              <Button plain onClick={() => openEditFAQModal(faq)} disabled={isMapped}>
                                Edit
                              </Button>
                              <Button plain destructive onClick={() => handleDeleteFAQ(id)}>
                                Delete
                              </Button>
                            </div>
                          </InlineStack>
                        </ResourceItem>
                      );
                    }}
                    selectedItems={[]}
                    selectable={false}
                  />
                </Card>
              </div>
         
              {showNextButton && (
                <div style={{ marginTop: '20px' }}>
                  <Button 
                    primary 
                    onClick={() => setStep(2)} 
                    disabled={nextButtonDisabled} 
                    variant='primary' 
                    tone='success' 
                    size='large' 
                    fullWidth
                  >
                    Next
                  </Button>
                </div>
              )}
        
              {!generateSchemaDisabled && (
                <div style={{ marginTop: '10px' }}>
                <Button
                  variant='primary'
                  tone="success"
                  fullWidth
                  onClick={handleGenerateSchema}
                  disabled={generateSchemaDisabled}
                  loading={loading} 
                >
                  Generate Schema
                </Button>
              </div>
              
              )}
            </Layout.Section>
          </>
        )}

        {step === 2 && (
          <>
            <Layout.Section>
              <Banner title="FAQ Schema Mapping" status="info">
                <p>Select an FAQ and map it to handles.</p>
              </Banner>
            </Layout.Section>

            <Layout.Section>
              <Card sectioned title="Select FAQ">
                <InlineStack vertical spacing="tight">
                  {faqs
                    .filter((faq) => !mappedFAQs.includes(faq.id))
                    .map((faq) => {
                      const { id, name } = faq;
                      return (
                        <RadioButton
                          key={id}
                          label={name}
                          checked={selectedFAQForMapping === id}
                          id={id.toString()}
                          name="faq"
                          onChange={() => {
                            setSelectedFAQForMapping(id);
                            setHandlesFetched(false);
                          }}
                        />
                      );
                    })}
                  <div style={{ marginLeft: 'auto' }}>
                    <Button variant='primary' onClick={() => setStep(1)}>
                      Back
                    </Button>
                  </div>
                </InlineStack>
              </Card>

              {selectedFAQForMapping && (
                <>
                  <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Button primary onClick={fetchHandles} disabled={fetchingHandles || !selectedFAQForMapping || handlesFetched} variant='secondary' fullWidth>
                      {fetchingHandles ? 'Fetching Handles...' : 'Fetch Handles'}
                    </Button>
                  </div>

                  {fetchingHandles && <Spinner accessibilityLabel="Fetching handles" size="large" />}

                  {loading || fetchingHandles ? (
                    <Spinner accessibilityLabel="Processing" size="large" />
                  ) : (
                    <Card title="Select Handles to Map" sectioned>
                      <div style={{ maxHeight: '450px', overflowY: 'auto' }}>
                        {handles.map((handle) => (
                          <div key={handle} style={{ marginBottom: '10px' }}>
                            <Checkbox
                              label={handle}
                              checked={selectedItems.includes(handle)}
                              onChange={() => handleSelectionChange(handle)}
                              disabled={mappedHandles.includes(handle)}
                            />
                          </div>
                        ))}
                      </div>
                    </Card>
                  )}

                  {selectedItems.length > 0 && (
                    <div style={{ marginTop: '10px' }}>
                      <InlineStack distribution="fillEvenly" spacing="loose">
                        <Button variant='primary' tone='success' onClick={handleSaveMappings} disabled={loading || selectedItems.length === 0} fullWidth>
                          Save Mappings
                        </Button>
                      </InlineStack>
                    </div>
                  )}
                </>
              )}
            </Layout.Section>
          </>
        )}
      </Layout>
      {toastMarkup}

      {showResetButton && (
        <div style={{ marginTop: '10px' }}>
          <Button
            variant='primary'
            tone="critical"
            fullWidth
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      )}

      <Modal
        open={modalActive}
        onClose={closeEditFAQModal}
        title={editingFAQ ? "Edit FAQ" : "Create FAQ"}
        primaryAction={{
          content: editingFAQ ? "Update FAQ" : "Save FAQ",
          onAction: editingFAQ ? handleUpdateFAQ : handleAddFAQ,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: closeEditFAQModal,
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label="FAQ Name"
            value={currentFAQ.name}
            onChange={(value) => setCurrentFAQ({ ...currentFAQ, name: value })}
            disabled={faqs.length >= 10}
          />
          {currentFAQ.questions.map((qa, index) => (
            <FormLayout key={index}>
              <TextField
                label={`Question ${index + 1}`}
                value={qa.question}
                onChange={(value) => handleFAQChange(index, 'question', value)}
              />
              <TextField
                label={`Answer ${index + 1}`}
                value={qa.answer}
                onChange={(value) => handleFAQChange(index, 'answer', value)}
              />
            </FormLayout>
          ))}
          {currentFAQ.questions.length < 10 && (
            <Button onClick={handleAddQuestion} disabled={faqs.length >= 10}>Add Question/Answer</Button>
          )}
        </Modal.Section>
      </Modal>
    </Page>
  );
}
