import React, { useState, useEffect } from 'react';
import {
  Page,
  Layout,
  Card,
  TextField,
  Button,
  Select,
  InlineStack,
  FormLayout,
  Toast,
  Banner,
} from '@shopify/polaris';
import { EditIcon, SaveIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';

const shop = new URLSearchParams(window.location.search).get('shop');

export default function SchemaSettings() {
  const navigate = useNavigate();
  const [organizationType, setOrganizationType] = useState('organization');
  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [companyLegalName, setCompanyLegalName] = useState('');
  const [websiteName, setWebsiteName] = useState('');
  const [description, setDescription] = useState('');
  const [telephone, setTelephone] = useState('');
  const [address, setAddress] = useState('');
  const [homepageShown, setHomepageShown] = useState('homeBrand');
  const [linkedin, setLinkedin] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [settingsSaved, setSettingsSaved] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [headerColor, setHeaderColor] = useState('#ffedcc');
  const [headerMessage, setHeaderMessage] = useState('Fill out the form to set up your schemas');

  const organizationTypes = [
    { label: 'Organization / Corporation', value: 'organization' },
    { label: 'Non-Profit', value: 'nonProfit' },
  ];

  const homepageOptions = [
    { label: 'Home / Brand Name', value: 'homeBrand' },
    { label: 'Brand Name / Home', value: 'brandHome' },
  ];

  const handleSubmit = async () => {
    // No strict validation, just saving whatever fields are filled
    const payload = {
      organizationType,
      companyName,
      companyLogo,
      companyLegalName,
      websiteName,
      description,
      telephone,
      address,
      homepageShown,
      linkedin,
      facebook,
      instagram,
      twitter,
      shop,
    };

    const response = await fetch('/api/schema/settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    if (response.ok) {
      setToastContent('Settings saved successfully');
      setToastActive(true);
      setSettingsSaved(true);
      setHeaderColor('#ccffcc');
      setHeaderMessage('Schema settings saved successfully');
      setTimeout(() => {
        navigate(`/dashboard?shop=${shop}`); // Redirecting to dashboard after save
        window.location.reload(); // Ensure navigation bar update
      }, 1000); // Added slight delay to allow time for save
    } else {
      setToastContent('Failed to save settings');
      setToastActive(true);
      console.error('Failed to save:', data);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const shop = new URLSearchParams(window.location.search).get('shop');

      const response = await fetch(`/api/schema/settings?shop=${shop}`);
      const data = await response.json();
      if (response.ok) {
        // Load the settings if available
        setOrganizationType(data.organizationType || 'organization');
        setCompanyName(data.companyName || '');
        setCompanyLogo(data.companyLogo || '');
        setCompanyLegalName(data.companyLegalName || '');
        setWebsiteName(data.websiteName || '');
        setDescription(data.description || '');
        setTelephone(data.telephone || '');
        setAddress(data.address || '');
        setHomepageShown(data.homepageShown || 'homeBrand');
        setLinkedin(data.linkedin || '');
        setFacebook(data.facebook || '');
        setInstagram(data.instagram || '');
        setTwitter(data.twitter || '');
        setSettingsSaved(true); // Indicate that the settings are loaded
        setHeaderColor('#ffedcc');
        setHeaderMessage('Schema settings loaded successfully');
      } else {
        setHeaderColor('#ffedcc');
        setHeaderMessage('Fill out the form to set up your schemas');
        console.error('Failed to fetch settings:', data);
      }
    };

    fetchData();
  }, []);

  const toggleEdit = () => {
    setSettingsSaved(false);
    setHeaderColor('#ffedcc');
    setHeaderMessage('Edit your schema settings');
  };

  const toastMarkup = toastActive ? (
    <Toast content={toastContent} onDismiss={() => setToastActive(false)} />
  ) : null;

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Banner title="Schema Settings" status="info" style={{ backgroundColor: headerColor }}>
            <p>{headerMessage}</p>
            <InlineStack gap="tight" alignment="center">
              <div style={{ marginLeft: 'auto' }}>
                <Button
                  variant="primary"
                  tone="success"
                  icon={SaveIcon}
                  onClick={handleSubmit}
                  disabled={settingsSaved}
                >
                  Save
                </Button>
                {settingsSaved && (
                  <Button variant="secondary" onClick={toggleEdit} icon={EditIcon} plain tone="success">
                    Edit
                  </Button>
                )}
              </div>
            </InlineStack>
          </Banner>
        </Layout.Section>

        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <Select
                label="Organization Type:"
                options={organizationTypes}
                value={organizationType}
                onChange={setOrganizationType}
                disabled={settingsSaved}
              />
              <TextField
                label="Company Name:"
                value={companyName}
                onChange={setCompanyName}
                placeholder="Enter your company name"
                disabled={settingsSaved}
              />
              <TextField
                label="Company Logo:"
                value={companyLogo}
                onChange={setCompanyLogo}
                placeholder="Copy your company logo URL and paste it here"
                disabled={settingsSaved}
              />
              <TextField
                label="Company Legal Name:"
                value={companyLegalName}
                onChange={setCompanyLegalName}
                placeholder="Enter your company legal name"
                disabled={settingsSaved}
              />
              <TextField
                label="Website Name (for breadcrumb):"
                value={websiteName}
                onChange={setWebsiteName}
                placeholder="Type how you want to show your homepage in breadcrumb"
                disabled={settingsSaved}
              />
              <TextField
                label="Description:"
                value={description}
                onChange={setDescription}
                placeholder="Describe your company"
                disabled={settingsSaved}
              />
              <TextField
                label="Telephone:"
                value={telephone}
                onChange={setTelephone}
                placeholder="Enter your telephone number"
                disabled={settingsSaved}
              />
              <TextField
                label="Address:"
                value={address}
                onChange={setAddress}
                placeholder="Enter your company address"
                disabled={settingsSaved}
              />
              <TextField
                label="LinkedIn:"
                value={linkedin}
                onChange={setLinkedin}
                placeholder="Enter your LinkedIn profile URL"
                disabled={settingsSaved}
              />
              <TextField
                label="Facebook:"
                value={facebook}
                onChange={setFacebook}
                placeholder="Enter your Facebook profile URL"
                disabled={settingsSaved}
              />
              <TextField
                label="Instagram:"
                value={instagram}
                onChange={setInstagram}
                placeholder="Enter your Instagram profile URL"
                disabled={settingsSaved}
              />
              <TextField
                label="Twitter:"
                value={twitter}
                onChange={setTwitter}
                placeholder="Enter your Twitter profile URL"
                disabled={settingsSaved}
              />
              <Select
                label="How Should Be Homepage Shown:"
                options={homepageOptions}
                value={homepageShown}
                onChange={setHomepageShown}
                disabled={settingsSaved}
              />
            </FormLayout>
          </Card>
        </Layout.Section>
      </Layout>
      {toastMarkup}
    </Page>
  );
}
