import React, { useState, useEffect } from 'react';
import {
  Page, Layout, Banner, Button, Toast, Spinner, Card, ResourceList, ResourceItem, Text
} from '@shopify/polaris';
import axios from 'axios';

const shop = new URLSearchParams(window.location.search).get('shop');

export default function ArticleSchemaPage() {
  const [blogs, setBlogs] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingBlogs, setFetchingBlogs] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [hasEnabledArticles, setHasEnabledArticles] = useState(false);
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    fetchSavedArticles();
  }, []);

  const fetchSavedArticles = async () => {
    try {
      const response = await axios.get(`/api/article/saved-articles?shop=${shop}`);
      const enabledArticles = response.data.flatMap(blog =>
        blog.articles.filter(article => article.enabled)
      );

      setHasEnabledArticles(enabledArticles.length > 0);
      if (enabledArticles.length > 0) {
        // Show only enabled articles and lock the list
        setBlogs(response.data.map(blog => ({
          ...blog,
          articles: blog.articles.filter(article => article.enabled)
        })));
        setSelectedItems(enabledArticles.map(article => article.id.toString()));
      } else {
        // Show all articles unlocked
        setBlogs(response.data);
        setSelectedItems([]);
      }
    } catch (error) {
      console.error('Error fetching saved articles:', error);
      setToastContent('Failed to fetch saved articles.');
      setToastActive(true);
    }
  };

  const fetchBlogs = async () => {
    try {
      setFetchingBlogs(true);
      const response = await axios.get(`/api/article/blogs-articles?shop=${shop}`);
      if (response.data) {
        setBlogs(response.data);
        setSelectedItems([]); // Unselect all on fetch
        setHasEnabledArticles(false); // Reset enabled state
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Error fetching blogs and articles:', error);
      setToastContent('Failed to fetch blogs and articles.');
      setToastActive(true);
    } finally {
      setFetchingBlogs(false);
    }
  };

  const handleGenerateSchema = async () => {
    if (selectedItems.length === 0) {
      setToastContent('Please select at least one article.');
      setToastActive(true);
      return;
    }

    setGenerating(true);
    try {
      await axios.post('/api/article/generate-article-schema', {
        shop,
        articles: selectedItems  // directly sending the list of IDs
      });

      setToastContent('Article schemas generated and saved successfully.');
      fetchSavedArticles(); // Refresh the list after generation to show only enabled ones
      // Step 3: Redirect back to the dashboard with the premium tab open
      window.location.href = `/dashboard?shop=${shop}&tab=premium`;
      
    } catch (error) {
      setToastContent('Failed to generate article schemas.');
      console.error('Error generating schemas:', error);
    } finally {
      setGenerating(false);
      setToastActive(true);
    }
  };

  const handleResetSchemas = async () => {
    try {
      setLoading(true);
      await axios.post('/api/article/reset-schemas', { shop });
      setToastContent('Schemas deleted successfully.');
      setBlogs([]); // Clear the blogs list
      setSelectedItems([]); // Clear selected items
      setHasEnabledArticles(false);  // Reset the enabled articles state
      fetchBlogs(); // Go back to the state where the user can fetch blogs
    } catch (error) {
      setToastContent('Failed to delete schemas.');
      console.error('Error deleting schemas:', error);
    } finally {
      setLoading(false);
      setToastActive(true);
    }
  };

  const handleSelectionChange = (newSelectedItems) => {
    setSelectedItems(newSelectedItems);
  };

  const toastMarkup = toastActive ? (
    <Toast content={toastContent} onDismiss={() => setToastActive(false)} />
  ) : null;

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Banner title="Article Schema" status="info">
            <p>Select articles to generate schema JSON files.</p>
          </Banner>
        </Layout.Section>

        <Layout.Section>
          {fetchingBlogs ? (
            <Spinner accessibilityLabel="Fetching blogs" size="large" />
          ) : (
            <>
              {blogs.length === 0 ? (
                <Button onClick={fetchBlogs} disabled={fetchingBlogs}>
                  {fetchingBlogs ? 'Fetching Blogs...' : 'Fetch Blogs'}
                </Button>
              ) : (
                <>
                  {loading || generating ? (
                    <Spinner accessibilityLabel="Processing" size="large" />
                  ) : (
                    <Card>
                      <div style={{ maxHeight: '450px', overflowY: 'auto' }}>
                        {blogs.map((blog) => (
                          <div key={blog.blog_id} style={{ marginBottom: '20px' }}>
                            <Text variant="headingMd" fontWeight="bold" as="h4">
                              {blog.blog_title}
                            </Text>
                            <ResourceList
                              resourceName={{ singular: 'article', plural: 'articles' }}
                              items={blog.articles.map((article) => ({
                                ...article,
                                blogTitle: blog.blog_title,
                              }))}
                              selectedItems={selectedItems}
                              onSelectionChange={handleSelectionChange}
                              selectable={!hasEnabledArticles} // Disable selection if there are enabled articles
                              renderItem={(article) => {
                                const { id, title, author, enabled } = article;
                                const authorMarkup = author ? <div>by {author}</div> : null;
                                return (
                                  <ResourceItem
                                    id={id.toString()}
                                    accessibilityLabel={`View details for ${title}`}
                                    name={title}
                                  >
                                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                                      {title} {enabled ? "(Generated)" : ""}
                                    </Text>
                                    {authorMarkup}
                                  </ResourceItem>
                                );
                              }}
                              showHeader={false}
                              bulkActions={false}
                            />
                          </div>
                        ))}
                      </div>
                    </Card>
                  )}
                  {!hasEnabledArticles && (
                    <Button
                      variant="primary"
                      tone="success"
                      fullWidth
                      onClick={handleGenerateSchema}
                      disabled={loading || generating || selectedItems.length === 0}
                      loading={generating}
                      style={{ marginTop: '20px', marginBottom: '20px' }}
                    >
                      Generate Schema
                    </Button>
                  )}
                  {hasEnabledArticles && (
                    <Button
                      variant="primary"
                      tone="critical"
                      fullWidth
                      onClick={handleResetSchemas}
                      destructive
                      style={{ marginTop: '20px', marginBottom: '20px' }}
                    >
                      Reset Schemas
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </Layout.Section>
      </Layout>
      {toastMarkup}
    </Page>
  );
}
